import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { ModalProvider } from "styled-react-modal"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Article, ArticleSection, ArticleHeader, ArticleDate, ArticleH1 } from "../../styledComponents/article"
import { FadingBackground } from "../../components/modalFadingBackground"
import {
  Section,
} from "../../styledComponents/section"
import { HeroTextImageWrapper, HeroText, HeroHeader, HeroDescription } from "../../styledComponents/heroBanner"
import Modal from "../../components/modal"
import { StyledOrderedList, StyledOrderedListItem } from "../../styledComponents/orderedList"
import Icon from "../../components/icon"
import { Link } from "gatsby"

const UxWebsiteReview = ({ data, location, createCookie }) => {
  return (
    <Layout location={location}>
      <Seo
        title="User Experience Design Services"
        description="Get the best user experience design services from our expert UX designers. Improve your website's usability, functionality, and engagement. Contact us today for a consultation."
        image=""
        url="/services/user-experience-website-review"
      />
      <HeroTextImageWrapper backgroundColor="var(--dark-blue-mid)" gridRowGap="0">
        <StaticImage
          loading="eager"
          layout="fullWidth"
          // // You can optionally force an aspect ratio for the generated image
          // aspectRatio={1 / 1}
          // // This is a presentational image, so the alt should be an empty string
          // alt=""
          // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
          src={
            "../../images/user-experience.png"
          }
          formats={["auto", "webp", "avif"]}
        />
        <HeroText fontColor="var(--white)">
          <HeroHeader level={1}>
          Seamless user experience
          </HeroHeader>
          <HeroDescription>Simplify your next website redesign by adopting a user-centered approach. This will create a website that meets both your users' requirements and your business goals.</HeroDescription>
          <ModalProvider backgroundComponent={FadingBackground}>
            <Modal
              title="Usability audit"
              type="hero"
              serviceName="ux"
              buttonLabel="Request a usability audit"
              buttonStyle="HeroButton"
              formExcerpt="Please fill in the following form and an expert will run a report and get back to you within 48 hours."
            />
          </ModalProvider>
        </HeroText>
      </HeroTextImageWrapper>

      <Article
        itemScope
        itemType="http://schema.org/Article"
      >
        <ArticleHeader>
          <ArticleH1 itemProp="headline" noMargin>Why Should you be getting a User Experience (UX) review? </ArticleH1>
          <ArticleDate offScreen pubdate dateTime=""><span className="publishedon">Published on</span> 28th October 2020</ArticleDate>
          {/* <ShareTools shareTitle="Share this on" pageLocation="" postTitle="" /> */}
        </ArticleHeader>
        <ArticleSection isList>

          <p>Do you have an existing website that isn't getting the sales or the customers?</p>
          <p>Improving your website's user experience (UX) can have a significant impact on your business success. A well-designed UX can increase user engagement, drive conversions and build trust with your audience. It can also save you time and money in the long run by reducing the need for costly revisions. </p>

          <p>Investing in UX design is a smart business move that can help you create a website that provides a positive user experience, increases engagement and drives business growth. Don't overlook the importance of UX – it can make all the difference for your website's success.</p>

          <h2>Why UX Design?</h2>
          <p>User experience design (UX) is a critical aspect of website development that can greatly impact the success of your website. UX design focuses on creating an intuitive, enjoyable and efficient experience for website users. It's not just about making a website look good, but about providing a seamless, intuitive experience that helps users achieve their goals. By prioritizing UX, you can improve user engagement, increase conversion rates and ultimately drive business success.</p>

          <p>A well-designed website will not only look good but will also be easy to navigate. This is achieved through careful consideration of factors such as layout, color scheme and typography. By using UX best practices, you can ensure that users can quickly find what they're looking for and complete tasks without frustration. For example, having a clear hierarchy of information and using intuitive navigation can make it easier for users to find what they need, increasing their engagement and satisfaction.</p>

          <p>Another important aspect of UX design is <Link href="/services/website-accessibility-review">accessibility</Link>. This refers to the ability of a website to be used by people with disabilities, including visual, auditory, motor and cognitive disabilities. Making your website accessible to everyone can increase its reach, improve user experience and build trust with your audience.</p>

          <Section background="var(--mid-blue-mid)" type="pullout">
            <h2>Every $1 invested in UX results in a return of $100 (ROI = 9,900%).</h2>
          </Section>

          <p>In addition to providing a great user experience, UX design can also help you increase conversions. By understanding user behavior, you can create compelling calls to action that encourage users to take specific actions, such as making a purchase or signing up for a newsletter. The right design can help you highlight the most important information and make it easy for users to take action.</p>

          <p>Finally, investing in UX design can also save you time and money in the long run. By focusing on creating an effective and efficient user experience, you can reduce the need for costly and time-consuming revisions later on. This allows you to get your website up and running more quickly and cost-effectively, freeing up resources that can be used for other areas of your business.</p>

          <p>In conclusion, using UX design when building a website is essential for creating a website that provides a positive user experience, is accessible to everyone, drives conversions and saves you time and money. A well-designed website can help you build trust with your audience, increase engagement and ultimately drive business success.</p>

         


          <StaticImage src="../../images/ux-design-process.jpg" className="article-inline-image" />

          <h2>Are you struggling to turn website visitors into customers? </h2>
          <p>It's a common issue that many businesses face, but the solution isn't always clear. Too often, websites are created based on opinions, not evidence, resulting in a pretty but poorly converting site. This approach can be costly in the long run, requiring constant and expensive redesigns to keep up with changing needs.</p>
          <p>But what if you could take the guesswork out of the redesign process and create a website with good user experience baked in from the start? That's where our UX Design approach comes in. By prioritizing user experience, we can help your website perform better and have a longer shelf life.</p>
          <p>Not ready for a full redesign yet? No problem. We can still make UX improvements to your existing WordPress website, uncovering pain points, removing friction, and simplifying paths to conversion. Our data-led approach ensures that decisions are based on facts, not subjective opinions, and that we truly understand your users and their needs.</p>
          <p>Don't settle for a website that looks good but doesn't perform. Choose our UX Design approach and start converting more visitors into customers today.</p>

          <ul className="process-ticks single-column">
            <li><Icon name="circle-tick" />Data-driven decision making: We use a data-led approach to ensure that every decision is based on facts and figures, not guesswork or assumptions.</li>
            <li><Icon name="circle-tick" />User-centric design: By getting to know your users and their needs, we create solutions that prioritize their experience and make your website more effective.</li>
            <li><Icon name="circle-tick" />Objective decision making: We remove subjectivity from the equation by relying on hard data and user feedback to make informed decisions that lead to better results.</li>
          </ul>
         
          <p>Depending on the situation and our client's needs. If the existing website has a solid foundation but could benefit from improved user experience, we may recommend making updates and improvements to enhance the current design. However, if the website is outdated or has significant UX issues, it may be necessary to rebuild the site from scratch. Ultimately, our approach will be tailored to meet the unique needs and goals of each individual client.</p>

  


        </ArticleSection>
      </Article>

      <Section background="var(--mid-blue-mid)" type="pullout">
            <h2>88% of users are less likely to return to a website after a bad user experience.</h2>
          </Section>

      <Article
        itemScope
        itemType="http://schema.org/Article"
      >
        <ArticleHeader>
          <h2 itemProp="headline" noMargin>UX Design FAQ’s </h2>
        </ArticleHeader>
        <ArticleSection>
          <Accordion allowMultipleExpanded="true">
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What is website user experience (UX), and why is it important?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>User experience (UX) design is a process that focuses on creating digital products that are easy to use and navigate, while providing a positive experience for users. The goal of UX design is to understand the needs and behaviors of users and design interfaces that align with those needs. UX design encompasses a range of disciplines, including research, prototyping, testing, and design. The process involves identifying user pain points, defining user personas, creating wireframes and mockups, and iterating on designs based on user feedback.</p>
                <p>UX design is important for websites because it can have a significant impact on user engagement, conversion rates, and customer loyalty. When users have a positive experience on a website, they are more likely to stay on the site longer, explore more pages, and return to the site in the future. Good UX design can also improve conversion rates by making it easier for users to complete tasks, such as making a purchase or filling out a form. Additionally, positive user experiences can help build customer loyalty and brand trust, which can lead to increased revenue over time.</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  How can I improve the user experience of my website?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p style={{ 'marginBottom': '1.6rem' }}>There are several ways to improve the user experience of your website, including:</p>
                <ul>
                  <li>Conducting user research to better understand your target audience's needs and preferences</li>
                  <li>Simplifying your site's navigation and making it easy for users to find what they're looking for</li>
                  <li>Ensuring that your site loads quickly and is mobile-friendly</li>
                  <li>Using clear and concise language in your content</li>
                  <li>Testing and iterating on your site design and functionality based on user feedback</li>
                </ul>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What are some common UX design principles?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>There are several common UX design principles that designers should consider when creating websites or digital products. One of the most important principles is simplicity. A simple design can help users quickly find the information they need and complete tasks more efficiently. Another important principle is consistency, which involves using the same design elements and patterns throughout the site to create a cohesive experience. Visibility is also key, as users should be able to easily see and access important information or features on the site.</p>
                <p>Feedback is another important principle of UX design. Users should receive clear feedback when they interact with the site, such as a confirmation message after submitting a form. This feedback can help users understand that their actions have been successful and reduce frustration or confusion. Finally, usability is a critical principle of UX design. A usable site is one that is easy to navigate, with clear labels and intuitive navigation. By adhering to these principles, designers can create digital products that are both functional and enjoyable to use.</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What is usability testing?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>Usability testing is a way to see how easy to use something is by testing it with real users. Users are asked to complete tasks, typically while they are being observed by a researcher, to see where they encounter problems and experience confusion. If more people encounter similar problems, recommendations will be made to overcome these usability issues.</p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What is A/B testing, and how can it improve website UX?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  A/B testing is the process of comparing two versions of a website (or a specific element on a website) to see which one performs better in terms of user engagement, conversion rates, or other metrics. By conducting A/B testing, you can identify areas of your website that need improvement and make changes to improve the user experience.</p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  How can I measure the effectiveness of my website's UX design?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  You can measure the effectiveness of your website's UX design by tracking user engagement and behavior using tools like Google Analytics. Metrics to look at include bounce rates, time on page, conversion rates, and user feedback. By analyzing these metrics, you can identify areas where your UX design could be improved and make changes to improve the user experience.</p>
              </AccordionItemPanel>
            </AccordionItem>



          </Accordion>
        </ArticleSection>
      </Article>
    </Layout >
  )
}

export default UxWebsiteReview
