import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base"
import { bold20, regular11 } from "./typography"

const StyledOrderedList = styled.ol`
    position: relative;
    list-style-type: none;
    ${baseGridStyles}
    padding: 1.6rem 0;
    margin-top: 0;
    padding-top: 1.6rem 0;
    counter-reset:li;
    margin-left:0; 
    padding-left:0;
    @media screen and (min-width: ${breakpoints.sm}px) {
        padding: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        padding: 0;
    }
`

const StyledOrderedListItem = styled.li`
    margin: 1rem 0;
    border-radius: 1rem;
    grid-column: span 4;
    padding: 1.6rem;
    background: var(--grey-lightest);

    position:relative; /* Create a positioning context */
    padding:1.6rem; /* Add some spacing around the content */
    list-style:none; /* Disable the normal item numbering */
    border-top:2px solid var(--grey-mid);
    background:#f6f6f6;

    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 2;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 4;
    }

    &:before {
        content: counter(li);
        counter-increment: li; 
        margin-right: 8px;
        padding: 10px;
        border: 2px solid #fff;
        color: #fff;
        background: var(--mid-blue-mid);
        font-weight: bold;
        font-family: "Helvetica Neue",Arial,sans-serif;
        text-align: center;
        position: absolute;
        right: 200px;
        right: 0;
        top: -2rem;
        width: 5.2rem;
        border-radius: 50%;
        ${bold20};
    }

    span {
        ${regular11}
        text-transform: uppercase;
    }

    h3 {
        font-weight: 700;
        padding-bottom: 0;
        margin-top: 1.6rem;
    }
    p {
        margin-top:0;
    } 
`
export {
    StyledOrderedList,
    StyledOrderedListItem
}